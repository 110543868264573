
export const CHANGE_SEARCH_FIELD = 'CHANGE_SEARCH_FIELD';

export const CLICK_BACK_TO_TOP = 'CLICK_BACK_TO_TOP';

export const LEFTSIDEBAR_CLOSE = 'LEFTSIDEBAR_CLOSE';
export const RIGHTSIDEBAR_CLOSE = 'RIGHTSIDEBAR_CLOSE';


export const CLICK_DROPDOWN = 'CLICK_DROPDOWN';
export const CLICK_MENU = 'CLICK_MENU';
export const CLICK_MESSAGES = 'CLICK_MESSAGES';
export const CLICK_SETTINGS = 'CLICK_SETTINGS';
export const CLICK_OUT = 'CLICK_OUT';


export const CHANGEROUTE = 'CHANGEROUTE';


export const SEGUNDA_CLICK = 'SEGUNDA_CLICK';
export const TERCA_CLICK = 'TERCA_CLICK';
export const QUARTA_CLICK = 'QUARTA_CLICK';
export const QUINTA_CLICK = 'QUINTA_CLICK';
export const SEXTA_CLICK = 'SEXTA_CLICK';
export const SABADO_CLICK = 'SABADO_CLICK';
export const DOMINGO_CLICK = 'DOMINGO_CLICK';

export const ACCORDION_SEGUNDA_CLICK = 'ACCORDION_SEGUNDA_CLICK';
export const ACCORDION_TERCA_CLICK = 'ACCORDION_TERCA_CLICK';
export const ACCORDION_QUARTA_CLICK = 'ACCORDION_QUARTA_CLICK';
export const ACCORDION_QUINTA_CLICK = 'ACCORDION_QUINTA_CLICK';
export const ACCORDION_SEXTA_CLICK = 'ACCORDION_SEXTA_CLICK';
export const ACCORDION_SABADO_CLICK = 'ACCORDION_SABADO_CLICK';
export const ACCORDION_DOMINGO_CLICK = 'ACCORDION_DOMINGO_CLICK';

export const CYCLESLIDESHOW = 'CYCLESLIDESHOW';

export const TOGGLEVISIBILITY = 'TOGGLEVISIBILITY';
export const ADDTOGGLEVISIBILITY = 'ADDTOGGLEVISIBILITY';
export const REMOVETOGGLEVISIBILITY = 'REMOVETOGGLEVISIBILITY';
export const HIDEALLEXCEPT = 'HIDEALLEXCEPT';

export const REQUEST_PRESENTATION_PENDING = 'REQUEST_PRESENTATION_PENDING';
export const REQUEST_PRESENTATION_SUCCESS = 'REQUEST_PRESENTATION_SUCCESS';
export const REQUEST_PRESENTATION_FAILED = 'REQUEST_PRESENTATION_FAILED';

export const UPDATE_PRESENTATION_PENDING = 'UPDATE_PRESENTATION_PENDING';
export const UPDATE_PRESENTATION_SUCCESS = 'UPDATE_PRESENTATION_SUCCESS';
export const UPDATE_PRESENTATION_FAILED = 'UPDATE_PRESENTATION_FAILED';

export const REQUEST_INTRO_PENDING = 'REQUEST_INTRO_PENDING';
export const REQUEST_INTRO_SUCCESS = 'REQUEST_INTRO_SUCCESS';
export const REQUEST_INTRO_FAILED = 'REQUEST_INTRO_FAILED';

export const UPDATE_INTRO_PENDING = 'UPDATE_INTRO_PENDING';
export const UPDATE_INTRO_SUCCESS = 'UPDATE_INTRO_SUCCESS';
export const UPDATE_INTRO_FAILED = 'UPDATE_INTRO_FAILED';

export const REQUEST_NEXTEVENT_PENDING = 'REQUEST_NEXTEVENT_PENDING';
export const REQUEST_NEXTEVENT_SUCCESS = 'REQUEST_NEXTEVENT_SUCCESS';
export const REQUEST_NEXTEVENT_FAILED = 'REQUEST_NEXTEVENT_FAILED';

export const UPDATE_NEXTEVENT_PENDING = 'UPDATE_NEXTEVENT_PENDING';
export const UPDATE_NEXTEVENT_SUCCESS = 'UPDATE_NEXTEVENT_SUCCESS';
export const UPDATE_NEXTEVENT_FAILED = 'UPDATE_NEXTEVENT_FAILED';

export const REQUEST_QUOTES_PENDING = 'REQUEST_QUOTES_PENDING';
export const REQUEST_QUOTES_SUCCESS = 'REQUEST_QUOTES_SUCCESS';
export const REQUEST_QUOTES_FAILED = 'REQUEST_QUOTES_FAILED';

export const UPDATE_QUOTES_PENDING = 'UPDATE_QUOTES_PENDING';
export const UPDATE_QUOTES_SUCCESS = 'UPDATE_QUOTES_SUCCESS';
export const UPDATE_QUOTES_FAILED = 'UPDATE_QUOTES_FAILED';

export const REQUEST_PORTFOLIO_PENDING = 'REQUEST_PORTFOLIO_PENDING';
export const REQUEST_PORTFOLIO_SUCCESS = 'REQUEST_PORTFOLIO_SUCCESS';
export const REQUEST_PORTFOLIO_FAILED = 'REQUEST_PORTFOLIO_FAILED';

export const UPDATE_PORTFOLIO_PENDING = 'UPDATE_PORTFOLIO_PENDING';
export const UPDATE_PORTFOLIO_SUCCESS = 'UPDATE_PORTFOLIO_SUCCESS';
export const UPDATE_PORTFOLIO_FAILED = 'UPDATE_PORTFOLIO_FAILED';

export const REQUEST_PORTFOLIO_TITLE_PENDING = 'REQUEST_PORTFOLIO_TITLE_PENDING';
export const REQUEST_PORTFOLIO_TITLE_SUCCESS = 'REQUEST_PORTFOLIO_TITLE_SUCCESS';
export const REQUEST_PORTFOLIO_TITLE_FAILED = 'REQUEST_PORTFOLIO_TITLE_FAILED';

export const UPDATE_PORTFOLIO_TITLE_PENDING = 'UPDATE_PORTFOLIO_TITLE_PENDING';
export const UPDATE_PORTFOLIO_TITLE_SUCCESS = 'UPDATE_PORTFOLIO_TITLE_SUCCESS';
export const UPDATE_PORTFOLIO_TITLE_FAILED = 'UPDATE_PORTFOLIO_TITLE_FAILED';

export const REQUEST_HORARIO_PENDING = 'REQUEST_HORARIO_PENDING';
export const REQUEST_HORARIO_SUCCESS = 'REQUEST_HORARIO_SUCCESS';
export const REQUEST_HORARIO_FAILED = 'REQUEST_HORARIO_FAILED';

export const UPDATE_HORARIO_PENDING = 'UPDATE_HORARIO_PENDING';
export const UPDATE_HORARIO_SUCCESS = 'UPDATE_HORARIO_SUCCESS';
export const UPDATE_HORARIO_FAILED = 'UPDATE_HORARIO_FAILED';

export const REQUEST_NOTA_FERIAS_PENDING = 'REQUEST_NOTA_FERIAS_PENDING';
export const REQUEST_NOTA_FERIAS_SUCCESS = 'REQUEST_NOTA_FERIAS_SUCCESS';
export const REQUEST_NOTA_FERIAS_FAILED = 'REQUEST_NOTA_FERIAS_FAILED';

/* export const UPDATE_NOTA_FERIAS_PENDING = 'UPDATE_NOTA_FERIAS_PENDING';
export const UPDATE_NOTA_FERIAS_SUCCESS = 'UPDATE_NOTA_FERIAS_SUCCESS';
export const UPDATE_NOTA_FERIAS_FAILED = 'UPDATE_NOTA_FERIAS_FAILED'; */

export const REQUEST_CONTACTS_PENDING = 'REQUEST_CONTACTS_PENDING';
export const REQUEST_CONTACTS_SUCCESS = 'REQUEST_CONTACTS_SUCCESS';
export const REQUEST_CONTACTS_FAILED = 'REQUEST_CONTACTS_FAILED';

export const REQUEST_METODOS_PENDING = 'REQUEST_METODOS_PENDING';
export const REQUEST_METODOS_SUCCESS = 'REQUEST_METODOS_SUCCESS';
export const REQUEST_METODOS_FAILED = 'REQUEST_METODOS_FAILED';

export const REQUEST_REDES_PENDING = 'REQUEST_REDES_PENDING';
export const REQUEST_REDES_SUCCESS = 'REQUEST_REDES_SUCCESS';
export const REQUEST_REDES_FAILED = 'REQUEST_REDES_FAILED';

export const UPDATE_DETAILS_PENDING = 'UPDATE_DETAILS_PENDING';
export const UPDATE_DETAILS_SUCCESS = 'UPDATE_DETAILS_SUCCESS';
export const UPDATE_DETAILS_FAILED = 'UPDATE_DETAILS_FAILED';

export const REQUEST_EVENTS_PENDING = 'REQUEST_EVENTS_PENDING';
export const REQUEST_EVENTS_SUCCESS = 'REQUEST_EVENTS_SUCCESS';
export const REQUEST_EVENTS_FAILED = 'REQUEST_EVENTS_FAILED';

export const UPDATE_EVENTS_PENDING = 'UPDATE_EVENTS_PENDING';
export const UPDATE_EVENTS_SUCCESS = 'UPDATE_EVENTS_SUCCESS';
export const UPDATE_EVENTS_FAILED = 'UPDATE_EVENTS_FAILED';

export const REQUEST_EVENTDETAILS_PENDING = 'REQUEST_EVENTDETAILS_PENDING';
export const REQUEST_EVENTDETAILS_SUCCESS = 'REQUEST_EVENTDETAILS_SUCCESS';
export const REQUEST_EVENTDETAILS_FAILED = 'REQUEST_EVENTDETAILS_FAILED';

export const REQUEST_EVENTPHOTOS_PENDING = 'REQUEST_EVENTPHOTOS_PENDING';
export const REQUEST_EVENTPHOTOS_SUCCESS = 'REQUEST_EVENTPHOTOS_SUCCESS';
export const REQUEST_EVENTPHOTOS_FAILED = 'REQUEST_EVENTPHOTOS_FAILED';

export const UPDATE_EVENTPHOTOS_PENDING = 'UPDATE_EVENTPHOTOS_PENDING';
export const UPDATE_EVENTPHOTOS_SUCCESS = 'UPDATE_EVENTPHOTOS_SUCCESS';
export const UPDATE_EVENTPHOTOS_FAILED = 'UPDATE_EVENTPHOTOS_FAILED';

export const DELETE_PHOTO_PENDING = 'DELETE_PHOTO_PENDING';
export const DELETE_PHOTO_SUCCESS = 'DELETE_PHOTO_SUCCESS';
export const DELETE_PHOTO_FAILED = 'DELETE_PHOTO_FAILED';

export const UPDATE_USERDATA_PENDING = 'UPDATE_USERDATA_PENDING';
export const UPDATE_USERDATA_SUCCESS = 'UPDATE_USERDATA_SUCCESS';
export const UPDATE_USERDATA_FAILED = 'UPDATE_USERDATA_FAILED';

export const UPDATE_LIKES_PENDING = 'UPDATE_LIKES_PENDING';
export const UPDATE_LIKES_SUCCESS = 'UPDATE_LIKES_SUCCESS';
export const UPDATE_LIKES_FAILED = 'UPDATE_LIKES_FAILED';

export const UPDATE_CLIENTLIST_PENDING = 'UPDATE_CLIENTLIST_PENDING';
export const UPDATE_CLIENTLIST_SUCCESS = 'UPDATE_CLIENTLIST_SUCCESS';
export const UPDATE_CLIENTLIST_FAILED = 'UPDATE_CLIENTLIST_FAILED';

export const DELETE_CLIENT_PENDING = 'DELETE_CLIENT_PENDING';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAILED = 'DELETE_CLIENT_FAILED';
