import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
        ChangeRoute
} from '../actions';

import Content from './Content';
import Footer from '../components/Basic/Footer';
import ErrorBoundary from './ErrorBoundary';
import AccountController from './AccountController';

import '../css/00_App.css';
import '../css/00_Page.css';
import '../css/Form.css';
import '../css/Input.css';
import '../css/Buttons.css';
import '../css/Card.css';

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onRouteChange: (text, id) => dispatch(ChangeRoute(text, id))
  }
}

class App extends Component {

  render() {

    const {
            onRouteChange
          } = this.props;

    return (
        <ErrorBoundary /* Container ??*/>
          <div className="App">
            <div className="after-nav-bar">
              <AccountController min_clearance_lvl_required={2}/>
              <Content/>
              <Footer onRouteChange={onRouteChange}/>
            </div>
          </div>
        </ErrorBoundary>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
