import React from 'react';
import '../../css/Footer.css';

const Footer = ({ onRouteChange }) => {

    return (
        <div className="footer-div">
            <div></div>
            <a href="#!" className="footer-link" title="Privacy Policy" onClick={() => onRouteChange('PagePrivacyPolicy', 0)}>
                <p>Política de Privacidade</p>
            </a>
            <a href="#!" className="footer-link" title="Conditions of Use" onClick={() => onRouteChange('PageConditionsOfUse', 0)}>
                <p>Condições de Uso</p>
            </a>
            <a href="#!" className="footer-link" title="Cookies" onClick={() => onRouteChange('PageCookies', 0)}>
                <p>Cookies</p>
            </a>
            <div></div>
            <div className="by">
                <a href="https://andrecorreiarocha.com" className="footer-link" title="author">
                    <p>Developed By &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; André Correia Rocha </p>
                </a>
            </div>
        </div>
    );
}

export default Footer;
