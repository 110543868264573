import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    ChangeRoute,
    SignOut
} from '../actions';

const mapStateToProps = (state) => {
    return {
        /* userdata: state.User.userdata, */

        user_data_isPending: state.User.user_data_isPending,
        user_data: state.User.user_data,
        user_data_isSuccess: state.User.user_data_isSuccess,
        response_user_data: state.User.response_user_data,
        message_user_data: state.User.message_user_data,
        error_user_data: state.User.error_user_data
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        onRouteChange: (text, id) => dispatch(ChangeRoute(text, id)),
        handle_SignOut: () => dispatch(SignOut())
    }
  }

class AccountController extends Component {

    render(){
        const {
            min_clearance_lvl_required,
            onRouteChange,
            handle_SignOut,
            
            user_data_isPending,
            user_data,
            /* user_data_isSuccess,
            response_user_data,
            message_user_data,
            error_user_data, */
        } = this.props;
        
      return (user_data_isPending || (!user_data.isSignedIn) || (!user_data.username) || (user_data.clearance_lvl_present < min_clearance_lvl_required)) ? 
        (<div className="accountmanager-notloggedin-div">
            <a href="#!" className="accountmanager_a" onClick = {() => onRouteChange('MainPage',0)}>Inicio</a>
            <span> | </span>
            <a href="#!" className="accountmanager_a" onClick = {() => onRouteChange('PageSignIn',0)}>Entrar</a>
            <span> | </span>
            <a href="#!" className="accountmanager_a" onClick = {() => onRouteChange('PageRegister',0)}>Registar</a>
        </div>) : 
        (<div className="accountmanager-loggedin-div">
            <span /* onClick = {() => onRouteChange('PageSignIn',0)} */>{user_data.username}</span><br/>
            <a href="#!" className="accountmanager_a" onClick = {() => onRouteChange('MainPage',0)}>Inicio</a>
            <span> | </span>
            <a href="#!" className="accountmanager_a" onClick = {() => onRouteChange('PageEditAccount',0)}>Editar</a>
            <span> | </span>
            <a href="#!" className="accountmanager_a"  onClick={() => handle_SignOut()}>Sair</a>
        </div>);

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountController);
